import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { RootState } from '@dtx-company/inter-app/src/redux/types'
import { getPlanTypeFromPlan } from '@dtx-company/inter-app/src/utils/misc/getPlanTypeFromPlan'
import { useSelector } from 'react-redux'

export const useGetLockedFeaturePlanType = (lockedFeature: string): PlanType => {
  const lockedFeatures = useSelector(
    (state: RootState) => state.revenueReducer.lockedFeatures.values
  )
  const value = lockedFeatures[lockedFeature]

  return getPlanTypeFromPlan(value) || PlanType.FREE
}
