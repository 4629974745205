import { ActionDataTypes } from '../types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { SocialAdvLinkType, SocialLinkFormFields, SocialLinkUrlFields } from '../Destination/types'
import { SocialLinkFields } from '../Destination/SocialLinkFields.types'
import { UseFormMethods } from 'react-hook-form-deprecated'
import { socialLinks } from '@dtx-company/inter-app/src/constants/linkTypes'

export const ALLOWED_LIBERTYMUTUAL_SOCIALS = [
  SocialLinkUrlFields.INSTAGRAM,
  SocialLinkUrlFields.TWITTER,
  SocialLinkUrlFields.FACEBOOK,
  SocialLinkUrlFields.LINKEDIN
]

export const ICONS_ONLY = 'iconsOnly'
export const DEFAULT_DISPLAY_TYPE = 'default'
export const FEATURED_DISPLAY_TYPE = 'featured'

const orderedLinkArray = [
  SocialLinkUrlFields.INSTAGRAM,
  SocialLinkUrlFields.FACEBOOK,
  SocialLinkUrlFields.TWITTER,
  SocialLinkUrlFields.YOUTUBE,
  SocialLinkUrlFields.SNAPCHAT,
  SocialLinkUrlFields.TIKTOK,
  SocialLinkUrlFields.LINKEDIN,
  SocialLinkUrlFields.SPOTIFY,
  SocialLinkUrlFields.TWITCH,
  SocialLinkUrlFields.PATREON,
  SocialLinkUrlFields.ONLYFANS,
  SocialLinkUrlFields.VENMO,
  SocialLinkUrlFields.CASH_APP,
  SocialLinkUrlFields.PINTEREST,
  SocialLinkUrlFields.APPLE_MUSIC,
  SocialLinkUrlFields.AMAZON_MUSIC,
  SocialLinkUrlFields.YOUTUBE_MUSIC,
  SocialLinkUrlFields.TIDAL,
  SocialLinkUrlFields.SOUNDCLOUD,
  SocialLinkUrlFields.DEEZER,
  SocialLinkUrlFields.PARADE_DECK
]

export const musicLinks: string[] = [
  SocialLinkUrlFields.AMAZON_MUSIC,
  SocialLinkUrlFields.SPOTIFY,
  SocialLinkUrlFields.APPLE_MUSIC,
  SocialLinkUrlFields.DEEZER,
  SocialLinkUrlFields.TIDAL,
  SocialLinkUrlFields.SOUNDCLOUD,
  SocialLinkUrlFields.YOUTUBE_MUSIC
]

export const RESTRICTED_LIBERTYMUTUAL_SOCIALS = orderedLinkArray.filter(
  link => !ALLOWED_LIBERTYMUTUAL_SOCIALS?.includes(link)
)

export const ALLOWED_VOYA_SOCIALS = orderedLinkArray.filter(
  link => link !== SocialLinkUrlFields.VENMO
)

export const RESTRICTED_VOYA_SOCIALS = orderedLinkArray.filter(
  link => link === SocialLinkUrlFields.VENMO
)

const socialLinksAmmended = [
  ...socialLinks,
  {
    provider: SocialLinkUrlFields.PINTEREST,
    displayText: 'Pinterest',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: SocialLinkUrlFields.APPLE_MUSIC,
    displayText: 'Apple Music',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: SocialLinkUrlFields.AMAZON_MUSIC,
    displayText: 'Amazon Music',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: SocialLinkUrlFields.YOUTUBE_MUSIC,
    displayText: 'Youtube Music',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: SocialLinkUrlFields.TIDAL,
    displayText: 'Tidal',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: SocialLinkUrlFields.SOUNDCLOUD,
    displayText: 'Soundcloud',
    prefix: '',
    isUsernameLink: false
  },
  {
    provider: SocialLinkUrlFields.DEEZER,
    displayText: 'Deezer',
    prefix: '',
    isUsernameLink: false
  }
] as SocialAdvLinkType[]

export const adjustedSocialLinks: Array<SocialAdvLinkType> = socialLinksAmmended
  .filter((link: SocialAdvLinkType) =>
    orderedLinkArray.includes(link.provider as SocialLinkUrlFields)
  )
  .sort(function (a: SocialAdvLinkType, b: SocialAdvLinkType) {
    return (
      orderedLinkArray.indexOf(a.provider.toLowerCase() as SocialLinkUrlFields) -
      orderedLinkArray.indexOf(b.provider.toLowerCase() as SocialLinkUrlFields)
    )
  })

export const emptySocialLinkFields = {
  link: '',
  order: -1,
  title: '',
  description: '',
  thumbNailImgUrl: ''
}

export enum IconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum IconStyle {
  CIRCLE = 'circle',
  FLOATING = 'floating'
}

export interface UseSocialLinkFormStateType {
  setValue: UseFormMethods['setValue']
  handleSubmit: UseFormMethods['handleSubmit']
  errors: UseFormMethods['errors']
  linkTheme: LinkType['linkTheme']
  setLinkTheme: (linkThemeInput: Partial<LinkType['linkTheme']>) => void
  onSubmit: ({
    instagram,
    facebook,
    twitter,
    youtube,
    snapchat,
    tiktok,
    linkedin,
    spotify,
    twitch,
    patreon,
    onlyfans,
    venmo,
    pinterest,
    displayType,
    iconSize,
    iconStyle
  }: SocialLinkFormFields) => Promise<void>
  watchAll: SocialLinkFormFields
  disabled: boolean
  edit: boolean
  trigger: (name?: string | string[] | undefined) => Promise<boolean>
  updateSelectedLinks: (linkType: SocialLinkUrlFields) => void
  selectedLinks: string[]
  setSelectedLinks: React.Dispatch<React.SetStateAction<string[]>>
  displayMoreOptions: boolean
  setDisplayMoreOptions: React.Dispatch<React.SetStateAction<boolean>>
  updateFormFieldValue: (
    value: string | number,
    social: SocialLinkUrlFields | undefined,
    nestedItem?: keyof SocialLinkFields | undefined
  ) => Promise<void>
  allowedSocialLinks: Array<SocialAdvLinkType>
  actionData: ActionDataTypes['socialLink'] | null
  expandedChildLinks: string[]
  setExpandedChildLinks: (providers: string[]) => void
}
