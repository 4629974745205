import { BoxProps, TextStyleProps } from '../../../types'
import { Icon } from '../Icon'
import { ReactNode } from 'react'
import { Text } from '../Text'
import { TooltipBox, TooltipContainer, TooltipContentBox } from './style'

export interface HoveredTooltipContentProps {
  children?: ReactNode
  text: string
  header?: string
  disable?: boolean
  icon?: string
}

export type HoveredTooltipStyleProps = {
  containerProps?: BoxProps
  tooltipProps?: BoxProps & { beforeRight?: boolean }
  textProps?: TextStyleProps
}
export type CombinedHoveredTooltipProps = HoveredTooltipContentProps & HoveredTooltipStyleProps

export const HoveredTooltip = ({
  children,
  containerProps,
  textProps,
  text,
  header,
  icon,
  tooltipProps,
  disable = false
}: CombinedHoveredTooltipProps): JSX.Element => {
  return (
    <>
      <TooltipContainer {...containerProps}>
        <TooltipBox data-testid="tooltip-tooltipbox">{children}</TooltipBox>
        {!disable && (
          <TooltipContentBox {...tooltipProps} data-testid="tooltip-contentbox">
            {icon && <Icon height="16px" width="16px" src={icon} />}
            {header && (
              <Text fontWeight="bold" {...textProps}>
                {header}
              </Text>
            )}
            <Text {...textProps}>{text}</Text>
          </TooltipContentBox>
        )}
      </TooltipContainer>
    </>
  )
}
