import { DestinationRestrictiveClients } from './landing-page'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'

export const useShouldRestrictLinksByClient = (): DestinationRestrictiveClients | undefined => {
  const { hasOperation } = useAuthState()
  const hasLibertyMutualOnlyLinks = hasOperation('view:liberty_mutual_links_only')
  const hasVoyaOnlyLinks = hasOperation('view:voya_links_only')

  let clientFilter = hasLibertyMutualOnlyLinks
    ? DestinationRestrictiveClients.LIBERTY_MUTUAL
    : undefined

  clientFilter = hasVoyaOnlyLinks ? DestinationRestrictiveClients.VOYA : clientFilter
  return clientFilter
}
