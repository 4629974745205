import { FC } from 'react'
import { MenuProps, MenuStyleProps } from '../../..'
import {
  border,
  boxShadow,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  zIndex
} from 'styled-system'
import styled from 'styled-components'

const MenuBase = styled.ul<MenuStyleProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  width: max-content;
  ${space}
  ${layout}
    ${position}
    ${color}
    ${border}
    ${boxShadow}
    ${flexbox}
    ${zIndex}
    ${grid}
`
export const Menu: FC<MenuProps> = ({
  children,
  component = 'ul',
  ...rest
}: MenuProps): JSX.Element => (
  <MenuBase as={component} {...rest} data-testid="menu-base">
    {children}
  </MenuBase>
)
