import { Box } from '../../atoms/Box/index'
import { DropdownProps } from '../../../types'
import { Element } from './styles'
import { FC, MouseEvent, useCallback, useState } from 'react'
import { ToggleArrow } from '../../atoms/ToggleArrow/index'
import OutsideClickHandler from 'react-outside-click-handler'

/**
 * theme color
 * @todo: consult the design team & use a theme color in border="1px solid #DEDFE0". - KAV
 */

export const DropdownMenu: FC<DropdownProps> = ({
  children,
  component = 'div',
  display,
  header,
  open = false,
  strokeColor = 'inherit',
  showToggleArrow = true,
  onOpen,
  onClose,
  className,
  disabled = false,
  backgroundColor = 'transparent',
  wrapperProps = {},
  disableAutoClose = false,
  activeBackgroundColor,
  activeColor,
  color,
  toggleArrowProps,
  ...rest
}: DropdownProps) => {
  const [dropdownMenuIsOpen, setDropdownMenuIsOpen] = useState(open)

  const onClickDropdown = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (dropdownMenuIsOpen) {
        if (
          disableAutoClose &&
          e.target instanceof HTMLDivElement &&
          e.target.ariaLabel !== 'dropdown-button'
        )
          return
        setDropdownMenuIsOpen(false)
        onClose?.()
      } else {
        setDropdownMenuIsOpen(true)
        onOpen?.()
      }
    },
    [disableAutoClose, dropdownMenuIsOpen, onClose, onOpen]
  )

  const closeDropdownMenu = (): void => {
    setDropdownMenuIsOpen(false)
    if (dropdownMenuIsOpen) onClose?.()
  }

  return (
    <Element as={component} display={display} className={className}>
      <OutsideClickHandler onOutsideClick={closeDropdownMenu} useCapture={true} display="contents">
        <Box
          display="block"
          padding="0"
          margin="0"
          position="relative"
          width="100%"
          backgroundColor={
            dropdownMenuIsOpen ? activeBackgroundColor || backgroundColor : backgroundColor
          }
          cursor={!disabled ? 'pointer' : undefined}
          onClick={(e: MouseEvent<HTMLDivElement>) => (!disabled ? onClickDropdown(e) : null)}
          color={dropdownMenuIsOpen ? activeColor || color : color}
          data-testid="dropdown-wrapper"
          {...wrapperProps}
        >
          {header && (
            <Box
              position="relative"
              border="1px solid #DEDFE0"
              aria-label="dropdown-button"
              {...rest}
            >
              <>
                {header}
                {showToggleArrow && (
                  <ToggleArrow
                    strokeColor={strokeColor}
                    open={dropdownMenuIsOpen}
                    {...toggleArrowProps}
                  />
                )}
              </>
            </Box>
          )}
          {dropdownMenuIsOpen && <>{children}</>}
        </Box>
      </OutsideClickHandler>
    </Element>
  )
}
