import { Box } from '../../atoms/Box/index'
import { DropdownStyleProps } from '../../..'
import { border, color, display, layout, space } from 'styled-system'
import styled from 'styled-components'

export const Element = styled(Box)<DropdownStyleProps>`
  width: 100%;
  display: inherit;
  ${display}
`
export const Header = styled(Box)`
  position: relative;
  ${space}
  ${color}
  ${layout}
  ${border}
`
