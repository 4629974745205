import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'

export type AnalyticsCollectorActionType =
  | 'CRM_WIDGET_ACTION_TYPE_VALID_SUBMISSION'
  | 'CRM_WIDGET_ACTION_TYPE_INVALID_SUBMISSION'
  | 'CRM_WIDGET_ACTION_TYPE_CLICK_TO_OPEN_MODAL'
  | 'CRM_WIDGET_ACTION_TYPE_CLICK_TO_DISMISS_MODAL'

export type AnalyticsCollectorDisplayType =
  | 'CRM_WIDGET_DISPLAY_TYPE_DEFAULT'
  | 'CRM_WIDGET_DISPLAY_TYPE_EMBED'
  | 'CRM_WIDGET_DISPLAY_TYPE_POPUP'

export const AnalyticsCollectorDisplayTypeMap = (
  displayType?: Link['displayType']
): AnalyticsCollectorDisplayType | undefined => {
  if (!displayType) return undefined
  const TypeMap: { [key: string]: AnalyticsCollectorDisplayType } = {
    default: 'CRM_WIDGET_DISPLAY_TYPE_DEFAULT',
    embed: 'CRM_WIDGET_DISPLAY_TYPE_EMBED',
    popup: 'CRM_WIDGET_DISPLAY_TYPE_POPUP'
  }

  return TypeMap[displayType]
}

export enum FlowpageAnalyticsCollectorEventTypes {
  LINK_CLICK = 'LINK_CLICK',
  PAGE_VIEW = 'PAGE_VIEW',
  CRM_WIDGET_ACTION = 'CRM_WIDGET_ACTION',
  STRIPE_CHECKOUT_SUCCESS = 'STRIPE_CHECKOUT_SUCCESS'
}

export enum FlowlyticsPageViewOriginContexts {
  ALL_CODES = 'all-codes',
  ALL_PAGES = 'all-pages',
  EVERYTHING = 'everything',
  FOLDER = 'folder',
  SINGLE_CODE = 'single-code',
  SINGLE_PAGE = 'single-page'
}

export interface HeapEventProperties {
  [key: string]: string
}
