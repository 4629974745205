import { Box } from '../Box'
import { maxWidth, position } from 'styled-system'
import { theme } from '../../../theme'
import styled from 'styled-components'
export const TooltipBox = styled(Box)``
export const TooltipContentBox = styled(Box)<{ beforeRight?: boolean }>`
  position: absolute;
  top: calc(100% + 5px);
  left: 10px;
  visibility: hidden;
  color: transparent;
  min-width: 100px;
  background-color: transparent;
  padding: 5px 5px;
  border-radius: 4px;
  transition: visibility 0.1s, color 0.1s, background-color 0.1s, width 0.1s, padding 0.1s;
  transition-timing-function: ease-in-out;
  &:before {
    content: '';
    width: 0;
    height: 0;
    ${({ beforeRight }) => (beforeRight ? 'right: 10%;' : 'left: 10%;')}
    top: -5px;
    position: absolute;
    border: 5px solid transparent;
    transform: rotate(135deg);
    transition: border 0.1s ease-in-out;
    ${({ borderColor }) => borderColor && `border-color: ${String(borderColor)};`}
  }
  ${position}
`
export const TooltipContainer = styled(Box)`
  cursor: pointer;
  position: relative;
  color: white;
  & ${TooltipBox}:hover + ${TooltipContentBox} {
    z-index: 201;
    visibility: visible;
    background-color: ${theme.colors.primary.flowBlue};
    max-width: 150px;
    word-break: break-word;
    padding: 8px 8px;
    &:before {
      border-color: transparent transparent ${theme.colors.primary.flowBlue}
        ${theme.colors.primary.flowBlue};
    }
    ${maxWidth}
  }
`
