import { SVGProps, memo } from 'react'

function EmbedSpotifySvg({
  stroke = '#111',
  color = '#1ED760',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
      <g>
        <path
          d="M12.7528 0.0766602C6.13314 0.0766602 0.76001 5.44979 0.76001 12.0695C0.76001 18.6892 6.13314 24.0623 12.7528 24.0623C19.3725 24.0623 24.7457 18.6892 24.7457 12.0695C24.76 5.44979 19.3869 0.0766602 12.7528 0.0766602Z"
          fill={stroke}
        />
        <path
          d="M12.7672 0.0766602C6.13314 0.0766602 0.76001 5.44979 0.76001 12.0695C0.76001 18.6892 6.13314 24.0623 12.7528 24.0623C19.3725 24.0623 24.7457 18.6892 24.7457 12.0695C24.76 5.44979 19.3869 0.0766602 12.7672 0.0766602ZM18.2693 17.3853C18.0543 17.7435 17.5958 17.8438 17.2376 17.6289C14.4149 15.9095 10.8758 15.5226 6.69195 16.4683C6.29076 16.5543 5.88956 16.3107 5.78926 15.9095C5.70329 15.5083 5.94688 15.1071 6.34807 15.0068C10.9188 13.9608 14.8448 14.405 17.997 16.3393C18.3696 16.5686 18.4842 17.0271 18.2693 17.3853ZM19.7308 14.1185C19.4585 14.5626 18.8854 14.7059 18.4412 14.4337C15.2173 12.442 10.3027 11.8689 6.49135 13.0295C5.98986 13.1728 5.47404 12.9005 5.33076 12.399C5.18747 11.8976 5.45971 11.3817 5.9612 11.2385C10.317 9.92024 15.7331 10.5507 19.4299 12.8289C19.8597 13.1011 20.003 13.6743 19.7308 14.1185ZM21.3928 10.3214C21.0776 10.8516 20.3899 11.0235 19.8597 10.7083C15.9911 8.41576 9.61493 8.20084 5.91822 9.31845C5.33076 9.50472 4.70031 9.16084 4.51404 8.57338C4.32777 7.98591 4.67165 7.35547 5.25911 7.1692C9.50031 5.87964 16.5499 6.13756 20.9916 8.77397C21.5361 9.0892 21.7081 9.77696 21.3928 10.3214Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export const EmbedSpotify = memo(EmbedSpotifySvg)

function EmbedSpotifyFilledWhiteSvg({
  stroke = 'transparent',
  color = '#1ED760',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
      <g clipPath="url(#clip0)">
        <path
          d="M12.7528 0.0766602C6.13314 0.0766602 0.76001 5.44979 0.76001 12.0695C0.76001 18.6892 6.13314 24.0623 12.7528 24.0623C19.3725 24.0623 24.7457 18.6892 24.7457 12.0695C24.76 5.44979 19.3869 0.0766602 12.7528 0.0766602Z"
          fill={stroke}
        />
        <path
          d="M12.7672 0.0766602C6.13314 0.0766602 0.76001 5.44979 0.76001 12.0695C0.76001 18.6892 6.13314 24.0623 12.7528 24.0623C19.3725 24.0623 24.7457 18.6892 24.7457 12.0695C24.76 5.44979 19.3869 0.0766602 12.7672 0.0766602ZM18.2693 17.3853C18.0543 17.7435 17.5958 17.8438 17.2376 17.6289C14.4149 15.9095 10.8758 15.5226 6.69195 16.4683C6.29076 16.5543 5.88956 16.3107 5.78926 15.9095C5.70329 15.5083 5.94688 15.1071 6.34807 15.0068C10.9188 13.9608 14.8448 14.405 17.997 16.3393C18.3696 16.5686 18.4842 17.0271 18.2693 17.3853ZM19.7308 14.1185C19.4585 14.5626 18.8854 14.7059 18.4412 14.4337C15.2173 12.442 10.3027 11.8689 6.49135 13.0295C5.98986 13.1728 5.47404 12.9005 5.33076 12.399C5.18747 11.8976 5.45971 11.3817 5.9612 11.2385C10.317 9.92024 15.7331 10.5507 19.4299 12.8289C19.8597 13.1011 20.003 13.6743 19.7308 14.1185ZM21.3928 10.3214C21.0776 10.8516 20.3899 11.0235 19.8597 10.7083C15.9911 8.41576 9.61493 8.20084 5.91822 9.31845C5.33076 9.50472 4.70031 9.16084 4.51404 8.57338C4.32777 7.98591 4.67165 7.35547 5.25911 7.1692C9.50031 5.87964 16.5499 6.13756 20.9916 8.77397C21.5361 9.0892 21.7081 9.77696 21.3928 10.3214Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" transform="translate(0.76001 0.0766602)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const EmbedSpotifyFilledWhite = memo(EmbedSpotifyFilledWhiteSvg)

function EmbedSpotifyBlackSvg({
  stroke = 'white',
  color = '#111111',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
      <g clipPath="url(#clip0_2487_310111)">
        <rect width="24" height="24" rx="12" fill={color} />
        <path
          d="M12 0C5.37634 0 0 5.37634 0 12C0 18.6237 5.37634 24 12 24C18.6237 24 24 18.6237 24 12C24.0143 5.37634 18.638 0 12 0Z"
          fill={stroke}
        />
        <path
          d="M12.0143 0C5.37634 0 0 5.37634 0 12C0 18.6237 5.37634 24 12 24C18.6237 24 24 18.6237 24 12C24.0143 5.37634 18.638 0 12.0143 0ZM17.5197 17.319C17.3047 17.6774 16.8459 17.7778 16.4875 17.5627C13.6631 15.8423 10.1219 15.4552 5.93548 16.4014C5.53405 16.4875 5.13262 16.2437 5.03226 15.8423C4.94624 15.4409 5.18996 15.0394 5.5914 14.9391C10.1649 13.8925 14.0932 14.3369 17.2473 16.2724C17.6201 16.5018 17.7348 16.9606 17.5197 17.319ZM18.9821 14.0502C18.7097 14.4946 18.1362 14.638 17.6918 14.3656C14.4659 12.3728 9.54839 11.7993 5.73477 12.9606C5.23297 13.1039 4.71685 12.8315 4.57348 12.3298C4.43011 11.828 4.70251 11.3118 5.2043 11.1685C9.56272 9.84946 14.9821 10.4803 18.681 12.7599C19.1111 13.0323 19.2545 13.6057 18.9821 14.0502ZM20.6452 10.2509C20.3297 10.7814 19.6416 10.9534 19.1111 10.638C15.2401 8.34409 8.86021 8.12903 5.16129 9.24731C4.57348 9.43369 3.94265 9.08961 3.75627 8.50179C3.56989 7.91398 3.91398 7.28315 4.50179 7.09677C8.74552 5.80645 15.7993 6.06452 20.2437 8.70251C20.7885 9.01792 20.9606 9.70609 20.6452 10.2509Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2487_310111">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export const EmbedSpotifyBlack = memo(EmbedSpotifyBlackSvg)
