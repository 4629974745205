import { ElementType, ForwardRefExoticComponent, forwardRef } from 'react'
import { IconProps } from '@mui/material'
import { default as MuiChip, ChipProps as MuiChipProps } from '@mui/material/Chip'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import clsx from 'clsx'
import type { IconComponent } from '../../types/util'

export type ChipProps = Pick<
  MuiChipProps,
  'ref' | 'avatar' | 'clickable' | 'disabled' | 'label' | 'onClick' | 'onDelete' | 'variant' | 'sx'
> & {
  /*
   * May only receive icons from `@mui/icons-material`
   */
  startIcon?: IconComponent

  /*
   * May only receive icons from `@mui/icons-material`
   */
  endIcon?: IconComponent
  /**
   * When true, the chip will appear selected
   */
  selected?: boolean
  /**
   * @default 'small'
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * @default 'filled'
   */
  variant?: 'filled' | 'outlined'
  /**
   * @default 'div'
   */
  component?: ElementType
  endIconProps?: IconProps
}

/**
 * Chips are compact elements that represent an input, attribute, or action.
 * Chips allow users to enter information, make selections, filter content, or trigger actions.
 *
 * @see https://mui.com/material-ui/react-chip for more details.
 *
 * @note Component overrides are defined in [./chipComponentOverrides.ts](./chipComponentOverrides.ts).
 */
const _Chip: ForwardRefExoticComponent<ChipProps> = forwardRef(
  (
    {
      component = 'div',
      startIcon: StartIcon,
      endIcon: EndIcon,
      selected,
      disabled,
      variant = 'filled',
      size = 'small',
      label,
      onClick,
      onDelete,
      endIconProps,
      ...props
    }: ChipProps,
    ref
  ) => {
    const noop = (): void => void 0
    let handleDelete = onDelete
    if (EndIcon) {
      handleDelete = onClick ? onClick : noop
    }
    return (
      <MuiChip
        ref={ref}
        component={component}
        label={label}
        variant={variant}
        size={size}
        icon={StartIcon && <StartIcon />}
        deleteIcon={EndIcon && <EndIcon {...endIconProps} />}
        onClick={onClick}
        onDelete={handleDelete}
        className={clsx({
          'Mui-disabled': disabled,
          'Mui-selected': selected,
          'MuiChip-endIcon': !!EndIcon
        })}
        {...props}
      />
    )
  }
)

_Chip.displayName = '_Chip'

interface ChipTypeMap {
  props: ChipProps
  defaultComponent: 'div'
}

export const Chip: OverridableComponent<ChipTypeMap> = _Chip

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    primary: false
    secondary: false
    success: false
    error: false
    info: false
    warning: false
  }

  interface ChipPropsSizeOverrides {
    large: true
  }

  interface ChipClasses {
    labelLarge: string
    sizeLarge: string
    iconLarge: string
    avatarLarge: string
    endIcon: string
  }
}
