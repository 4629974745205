import { SIZES } from '../../constants/mediaQueries'
import { useMediaQuery } from '../useMediaQuery'

/**
 * @deprecated use [useScreenSizeBreakpoints](apps/app/shared/common/src/hooks/useScreenSizeBreakpoints.ts) instead
 */
export function useIsMobile(): boolean {
  const mobile = useMediaQuery(`(max-width:${SIZES.tablet - 1}px)`)
  return mobile
}
