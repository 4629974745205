import * as React from 'react'
import {
  BorderProps,
  LayoutProps,
  SpaceProps,
  TypographyProps,
  ZIndexProps,
  space,
  zIndex
} from 'styled-system'
import { Icon } from '../../atoms/Icon/index'
import { Input } from '../../atoms/Input/index'
import { Option, SelectOption, TextStyleProps } from '../../../types'
import { Options } from '../../atoms/Options/index'
import arrowDown from '../../../static/icons/arrow-down.svg'
import styled, { css } from 'styled-components'
interface AutocompleteBaseProps extends SpaceProps, ZIndexProps {
  options: Option[]
  option: Option | null
  width?: LayoutProps['width']
  optionsWidth?: LayoutProps['width']
  height?: LayoutProps['height']
  onSelect: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent,
    option: Option
  ) => void
  onChange?: (value: string) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  placeholder?: string
  helperText?: string
  disabled?: boolean
  inline?: boolean
  startAdornment?: React.ReactElement
  endAdornment?: React.ReactElement
  error?: boolean
  labelProps?: TextStyleProps
  inputProps?: TypographyProps & BorderProps & React.HTMLProps<HTMLInputElement>
  autocompleteRef?: React.RefObject<HTMLInputElement>
  label?: string
  autoComplete?: string
}

const AutocompleteBase = styled.div<{ width: LayoutProps['width']; height: LayoutProps['height'] }>`
  position: relative;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  ${space}
  ${zIndex}
`

const StyledInput = styled(Input)`
  ${({ inline }: { inline: boolean }) => {
    if (inline) {
      return css`
        background: transparent;
        input {
          border-radius: 0px;
          border: none;
          &:hover {
            border: none;
          }
          &:focus {
            border: none;
            border-bottom: solid 2px ${({ theme }) => theme.colors.primary.black};
          }
        }
      `
    }
    return
  }}
`
const StyledIcon = styled(Icon)`
  transform: ${({ open }: { open: boolean }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: 0.25s all;
  margin-right: 6px;
`

export const fixInvalidAutofillOption = (
  options: Option[],
  option: Option | null,
  autocompleteRef: React.RefObject<HTMLInputElement> | undefined,
  curLabel: string,
  setCurLabel: (value: string) => void,
  onSelect: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent,
    option: Option
  ) => void
): void => {
  const isValidOption = Boolean(options.find(op => op.label === curLabel))
  if (curLabel !== option?.label && autocompleteRef?.current && !isValidOption) {
    // first assign to default if not provided
    let label = option?.label ?? '+1 (US / CA)'
    let value = (option?.value as string) ?? '1'

    // check if this is coming from browser autocomplete
    const searchLabel = curLabel[0] != '+' ? `+${curLabel} ` : curLabel
    const index = options.findIndex(op => op.label.startsWith(searchLabel))
    // must be coming from browser autocomplete
    if (index >= 0) {
      const selectedOption = options[index]
      label = selectedOption.label

      value = curLabel.replace('+', '')
      //update current option
      if (option) {
        option.label = label
        option.value = selectedOption.value
        //autofill selected
        onSelect({} as React.KeyboardEvent<HTMLInputElement>, option)
      }
    }

    const thisInput = autocompleteRef?.current as HTMLInputElement
    if (thisInput) {
      thisInput.value = value
      setCurLabel(label)
    }
  }
}
export const Autocomplete: React.FC<AutocompleteBaseProps> = ({
  options,
  onFocus,
  option,
  onSelect,
  onChange,
  onBlur,
  inline = false,
  labelProps,
  inputProps,
  placeholder,
  disabled,
  startAdornment,
  error,
  width = '305px',
  optionsWidth = '305px',
  height = '44px',
  helperText,
  label,
  autocompleteRef,
  autoComplete,
  ...rest
}: AutocompleteBaseProps) => {
  const [curOptions, setOptions] = React.useState<Option[]>(options)
  const [open, setOpen] = React.useState(false)
  const [focused, setFocused] = React.useState<number>(0)
  const [curLabel, setCurLabel] = React.useState<string>(option?.label || '')
  // clear (autofill) filling in an unspecified option
  React.useEffect(() => {
    fixInvalidAutofillOption(options, option, autocompleteRef, curLabel, setCurLabel, onSelect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curLabel])
  React.useEffect(() => {
    setOptions(options)
  }, [options])
  React.useEffect(() => {
    setCurLabel(option?.label || '')
  }, [option])
  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: Option | SelectOption,
    idx: number
  ): void => {
    setFocused(idx)
    setCurLabel('')
    onSelect(e, option)
    setOpen(!open)
  }
  const filterOptions = (val: string): void => {
    const valToCheck = val?.toLowerCase()
    const newOptions = options.filter((optionToCheck: Option) => {
      if (optionToCheck.label?.toLowerCase()?.includes(valToCheck)) {
        return true
      }
      if (typeof optionToCheck.value === 'string') {
        return String(option)?.toLowerCase()?.includes(valToCheck)
      }
      if (typeof optionToCheck.value === 'object') {
        const subOptions = Object.values(optionToCheck.value).filter(subOption => {
          return typeof subOption === 'string'
            ? subOption?.toLowerCase()?.includes(valToCheck.toString())
            : false
        })

        return subOptions.length > 0
      }
    })
    setOptions(newOptions)
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    filterOptions(e.target.value)
    setCurLabel(e.target.value)
    if (onChange) {
      onChange(e.target.value)
    }
  }

  const onEnter = (e: React.KeyboardEvent, option: Option | SelectOption, idx: number): void => {
    onSelect(e, option)
    setFocused(idx)
    setCurLabel(option.label)
    setOpen(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    // up
    if (e.keyCode === 38) {
      const index = focused - 1 >= 0 ? focused - 1 : curOptions?.length - 1
      setFocused(index)
      e.preventDefault()
    }

    // down
    if (e.keyCode === 40) {
      const index = focused + 1 <= curOptions?.length - 1 ? focused + 1 : 0
      setFocused(index)
      e.preventDefault()
    }

    // enter
    if (e.keyCode === 13) {
      setCurLabel(curOptions[focused].label)
      onSelect(e, curOptions[focused])
      setOpen(!open)
    }

    // esc
    if (e.keyCode === 27 && open) {
      setOpen(false)
    }
  }
  return (
    <AutocompleteBase
      width={width}
      height={height}
      onKeyDown={handleKeyDown}
      ref={autocompleteRef}
      {...rest}
    >
      <StyledInput
        height={height}
        type="text"
        label={label}
        autoComplete={autoComplete}
        data-testid="autocomplete-test-id"
        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
          setOpen(true)
          onFocus && onFocus(e)
        }}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          setOpen(false)
          onBlur && onBlur(e)
        }}
        placeholder={placeholder}
        disabled={disabled}
        labelProps={labelProps}
        inline={inline}
        inputProps={inputProps}
        error={error}
        helperText={helperText}
        startAdornment={startAdornment}
        endAdornment={
          <StyledIcon
            button
            type="button"
            onClick={e => {
              e.preventDefault()
              setOpen(!open)
            }}
            src={arrowDown}
            open={open}
          />
        }
        value={curLabel}
        controlled
        onChange={handleChange}
        maxWidth={width}
      />
      {!disabled && open && !!curOptions.length && (
        <Options
          open={open}
          focused={focused}
          onOptionSelect={handleSelect}
          width={optionsWidth}
          onOptionEnter={onEnter}
          options={curOptions}
        />
      )}
    </AutocompleteBase>
  )
}
