import { GOOGLE_DOC_DESTINATION } from '../Homepage/CreateFlowcodeForm/CreateFlowcodeFormMobile/ScanDestination/ScanDestination.contants'
import {
  LandingPageDestination,
  LandingPageDestinationType
} from '@dtx-company/inter-app/src/types/flowcode'
import { SOCIAL_MEDIA_DESTINATIONS } from '../../constants/landing-page'

export enum DestinationRestrictiveClients {
  LIBERTY_MUTUAL = 'liberty_mutual',
  VOYA = 'voya'
}

export const filterStaticLandingPageDestinations = (
  destinations: LandingPageDestinationType[]
): LandingPageDestinationType[] =>
  destinations.filter(
    el => el.id !== LandingPageDestination.EVENT && el.id !== LandingPageDestination.WIFI
  )

export const filterLibertyMutualLandingPageDestinations = (
  destinations: LandingPageDestinationType[]
): LandingPageDestinationType[] => destinations.filter(el => isAllowedLibertyMutualLinkType(el))

export const filterRestrictedLandingPageDestinations = (
  restrictedLinks: string[],
  destinations: LandingPageDestinationType[]
): LandingPageDestinationType[] => {
  return destinations.filter(
    destination => !restrictedLinks?.some?.(link => link === destination.id.toLowerCase())
  )
}

export const isValidRestrictedURLForClient = (url: string, restrictedLinks: string[]): boolean => {
  let isValid = true
  const linksToCheck = [...SOCIAL_MEDIA_DESTINATIONS, GOOGLE_DOC_DESTINATION]
  linksToCheck.forEach(destination => {
    if (destination.prefix) {
      if (
        url.includes(destination.prefix) ||
        url.includes(destination.prefix?.replace('https://', ''))
      ) {
        let isAllowed = true
        //atlas configs map to LandingPageDestination ids
        isAllowed = !restrictedLinks.some(link => link === destination.id?.toLowerCase())
        if (!isAllowed) isValid = false
      }
    }
  })

  return isValid
}

export const isAllowedLibertyMutualLinkType = (
  destination: LandingPageDestinationType
): boolean => {
  return (
    destination.id !== LandingPageDestination.SNAPCHAT &&
    destination.id !== LandingPageDestination.TIKTOK &&
    destination.id !== LandingPageDestination.YOUTUBE &&
    destination.id !== LandingPageDestination.SMS &&
    destination.id !== LandingPageDestination.SPOTIFY &&
    destination.id !== LandingPageDestination.VENMO
  )
}

export const isAllowedVoyaLinkType = (destination: LandingPageDestinationType): boolean => {
  return (
    destination.id !== LandingPageDestination.VENMO &&
    destination.id !== LandingPageDestination.GOOGLE_DOC
  )
}
