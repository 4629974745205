import { LockedFeaturesUpsellContent } from '../../../constants/lockedFeatures'
import { setLockedFeatureModal } from '@dtx-company/inter-app/src/redux/slices/lockedFeatureModalSlice'
import { useDispatch } from 'react-redux'
import { useGetLockedFeaturePlanType } from '../../../hooks/useGetLockedFeaturePlanType/useGetLockedFeaturePlanType'
import { useLockedFeatureModalContext } from '../context/useLockedFeatureModalContext'

export const useLockedFeatureAction = ({
  lockedFeatureName
}: {
  lockedFeatureName: string
}): (({
  onOpen,
  onClose
}: {
  onOpen?: () => void
  onClose?: (reason?: 'success' | 'dismiss') => void
}) => void) => {
  const dispatch = useDispatch()
  const { setOnClose } = useLockedFeatureModalContext()
  const lockedFeaturePlanType = useGetLockedFeaturePlanType(lockedFeatureName)
  const lockContent = LockedFeaturesUpsellContent[lockedFeatureName]

  const openLockedFeatureModal = ({
    onOpen,
    onClose
  }: {
    onOpen?: () => void
    onClose?: (reason?: 'success' | 'dismiss') => void
  }): void => {
    onOpen?.()
    dispatch(setLockedFeatureModal({ lockedFeatureName, planType: lockedFeaturePlanType }))
    lockContent?.trackingCall?.()
    setOnClose(onClose)
  }
  return openLockedFeatureModal
}
