import { flowpageRoot, getFlowpageRootUrl } from '../../constants/page'

export function slugToUrl(slug: string): string {
  return `${getFlowpageRootUrl()}/${slug}`
}
export function slugToUrlFullRoute(slug?: string, domain?: string | null): string {
  const domainWithSlash = domain?.endsWith('/') ? domain : domain + '/'
  return `${domain ? domainWithSlash : getFlowpageRootUrl() + '/'}${slug?.toLowerCase()}`
}
export function slugToDisplayUrl(slug: string): string {
  return `${flowpageRoot}${slug?.toLowerCase()}`
}

export const extractFlowpageSlug = (link: string): string => {
  return link.slice(link.lastIndexOf('/') + 1)
}

export const getCashAppUserName = (value: string): string => {
  if (value.length && !value.startsWith('$')) return `$${value}`
  return value
}
