import { RefObject, useCallback, useEffect, useState } from 'react'

interface Rect {
  width: number
  height: number
}

function getSize(el: HTMLElement | null): Rect {
  if (!el) {
    return { width: 0, height: 0 }
  }
  return {
    width: el.offsetWidth,
    height: el.offsetHeight
  }
}

// TODO: move to shared utils?
function useComponentSize(ref: RefObject<HTMLElement>): Rect {
  const [componentSize, setComponentSize] = useState(getSize(ref.current))

  const handleResize = useCallback(() => {
    if (ref.current) {
      setComponentSize(getSize(ref?.current))
    }
  }, [ref])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    handleResize()
    const el = ref.current
    if (typeof window.ResizeObserver === 'function') {
      const resizeObserver = new window.ResizeObserver(function () {
        handleResize()
      })
      resizeObserver.observe(el)

      return function () {
        resizeObserver.disconnect()
      }
    }
  }, [ref, handleResize])

  return componentSize
}

export default useComponentSize
