import { CardProps, CardStyleProps } from '../../../types'
import {
  background,
  border,
  color,
  flexbox,
  layout,
  opacity,
  position,
  shadow,
  space,
  variant
} from 'styled-system'

import { Box } from '../Box'
import { CARD_VARIANTS } from '../../../constants'
import { FC } from 'react'
import { Text } from '../Text'
import { theme } from '../../../theme'
import ribbonImg from '../../../static/ribbon.svg'
import styled from 'styled-components'

const CardBase = styled(Box)<CardStyleProps>`
  background-color: ${theme.colors.primary.white};
  border-radius: ${theme.radii[2]}px;
  box-shadow: ${theme.shadows.float};
  padding: 21px;
  width: 335px;
  max-width: 100%;
  display: block;
  position: relative;
  ${border}
  ${color} 
  ${flexbox} 
  ${layout} 
  ${opacity} 
  ${position}
  ${shadow}
  ${space}
  ${background}
  ${variant({
    variants: CARD_VARIANTS
  })}
`

const Label = styled(Text).attrs(() => ({ variant: 'body/small' }))`
  position: absolute;
  top: 12px;
  right: 12px;
  font-weight: 600;
  padding: 2px 8px;
  width: min-content;
  height: min-content;
  line-height: 1.77;
  border-radius: 4px;
`
const Ribbon = styled.img`
  position: absolute;
  top: -26.5px;
  right: -26.5px;
`

export const Card: FC<CardProps> = ({
  children,
  variant,
  label,
  ribbon,
  onClick,
  ...rest
}: CardProps) => {
  return (
    <CardBase onClick={onClick} cursor={onClick ? 'pointer' : 'auto'} variant={variant} {...rest}>
      {label && (
        <Label backgroundColor="status.successLight" color="primary.flowBlue">
          {label}
        </Label>
      )}
      {ribbon && <Ribbon alt="new" src={ribbonImg} />}
      {children}
    </CardBase>
  )
}

export const FloatingCard: FC<CardProps> = (props: CardProps) => {
  return <Card variant="floating" {...props} />
}

export const FlatCard: FC<CardProps> = (props: CardProps) => {
  return <Card variant="flat" {...props} />
}

export const FlatFilledCard: FC<CardProps> = (props: CardProps) => {
  return <Card variant="flatFilled" {...props} />
}
