import { CardVariants, CardVariantsType } from '../types'

import { theme } from '../theme'

export const cardVariantOptions: CardVariantsType[] = ['flat', 'flatFilled', 'floating']

export const CARD_VARIANTS: CardVariants = {
  floating: {
    opacity: 1,
    boxShadow: `${theme.shadows.float}`,
    border: `1px solid ${theme.colors.secondary.border}`,
    cursor: 'auto',
    borderRadius: '12px'
  },
  flat: {
    opacity: 1,
    boxShadow: 'none',
    border: `1px solid ${theme.colors.secondary.border}`,
    borderRadius: '12px'
  },
  flatFilled: {
    opacity: 1,
    boxShadow: 'none',
    backgroundColor: theme.colors.secondary.backgroundDark,
    borderRadius: '12px'
  }
}
