import { DividerProps } from '../../../types'
import { display, margin } from 'styled-system'
import { theme } from '../../../theme'
import styled from 'styled-components'

export const Divider = styled.hr<DividerProps>`
  width: ${props => props.width || '100%'};
  border-top-color: ${props => props.color || theme.colors.secondary.border};
  border-top-style: ${props => props.borderStyle || 'solid'};
  border-top-width: ${props => props.borderWidth || '1px'};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  border-left: none;
  ${margin}
  ${display}
`
