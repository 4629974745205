import {
  LockedFeatureModalContext,
  LockedFeatureModalContextType
} from './LockedFeatureModalProvider'
import { useContext } from 'react'

export function useLockedFeatureModalContext(): LockedFeatureModalContextType {
  const context = useContext(LockedFeatureModalContext)
  if (!context) {
    throw new Error(
      'useLockedFeatureModalContext must be used within locked feature modal context provider'
    )
  }
  return context
}
