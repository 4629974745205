import { RefObject, useEffect, useState } from 'react'
import { SIZES } from '@dtx-company/true-common/src/constants/mediaQueries'

export function capitalize(s: string | null): string {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const autoscroll = (ref: RefObject<HTMLElement | null>): void => {
  if (ref.current) {
    const { top, bottom } = ref.current.getBoundingClientRect()
    const { scrollY, innerHeight } = window
    if (top < 0) window.scrollTo({ top: top + scrollY - 10, behavior: 'smooth' })
    else if (bottom > innerHeight)
      window.scrollTo({ top: bottom + scrollY - innerHeight + 10, behavior: 'smooth' })
  }
}

/**
 * @deprecated use `useMediaQuery` imported from `@mui/material` instead
 */
export function useMediaQuery(bp: SIZES): boolean {
  const [query, setQuery] = useState<boolean>(false)

  useEffect(() => {
    if (typeof window.matchMedia !== 'undefined') {
      setQuery(window.matchMedia(`(max-width: ${bp}px)`).matches)

      const setMediaMatchHandler = (e: MediaQueryListEvent): void => {
        setQuery(e.matches)
      }
      window.matchMedia(`(max-width: ${bp}px)`)?.addListener(setMediaMatchHandler)
      return () => window.matchMedia(`(max-width:  ${bp}px)`).removeListener(setMediaMatchHandler)
    }
  }, [setQuery, bp])

  return query
}

export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const dataUrl = reader.result
      resolve(dataUrl as string)
    }
    reader.onerror = error => {
      reject(error)
    }
    reader.readAsDataURL(blob)
  })
}

const cachedScripts: string[] = []

// https://usehooks.com/useScript/

const useScript = (src: string): boolean[] => {
  // Keeping track of script loaded and error state
  const [state, setState] = useState({
    loaded: false,
    error: false
  })

  useEffect(
    () => {
      // If cachedScripts array already includes src that means another instance ...
      // ... of this hook already loaded this script, so no need to load again.
      if (cachedScripts.includes(src)) {
        setState({
          loaded: true,
          error: false
        })
      } else {
        cachedScripts.push(src)

        // Create script
        const script = document.createElement('script')
        script.src = src
        script.async = true

        // Script event listener callbacks for load and error
        const onScriptLoad = (): void => {
          setState({
            loaded: true,
            error: false
          })
        }

        const onScriptError = (): void => {
          // Remove from cachedScripts we can try loading again
          const index = cachedScripts.indexOf(src)
          if (index >= 0) cachedScripts.splice(index, 1)
          script.remove()

          setState({
            loaded: true,
            error: true
          })
        }

        script.addEventListener('load', onScriptLoad)
        script.addEventListener('error', onScriptError)

        // Add script to document body
        document.body.appendChild(script)

        // Remove event listeners on cleanup
        return () => {
          script.removeEventListener('load', onScriptLoad)
          script.removeEventListener('error', onScriptError)
        }
      }
      return
    },
    [src] // Only re-run effect if script src changes
  )

  return [state.loaded, state.error]
}
export default useScript
