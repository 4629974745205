import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxLinkProps, ButtonLinkProps, ImageLinkProps, TextLinkProps } from '../../types/links'
import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { ChangeEvent, FC } from 'react'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { ImageStyleProps } from '@dtx-company/shared-components/src'
import { ParsedUrlQueryInput } from 'querystring'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { UrlObject } from 'url'
import Link from 'next/link'

export const ButtonLink: FC<ButtonLinkProps & { 'data-heap-id'?: string }> = ({
  children,
  href,
  wrapperProps,
  dataTestId,
  'data-heap-id': dataHeapId,
  ...props
}) => (
  <Link
    href={href}
    style={{ display: 'contents' }}
    data-testid={dataTestId}
    data-heap-id={dataHeapId}
  >
    <Box display="block" {...wrapperProps}>
      <Button {...props}>{children}</Button>
    </Box>
  </Link>
)

export const TextLink: FC<TextLinkProps> = ({
  children,
  href,
  wrapperProps,
  isExternal,
  onClick,
  ...rest
}: TextLinkProps) => {
  if (isExternal && typeof href === 'string') {
    return (
      <Box display="block" {...wrapperProps}>
        <Text
          component="a"
          href={href}
          cursor="pointer"
          target="_blank"
          rel="noopener noreferrer"
          onClick={onClick}
          {...rest}
        >
          {children}
        </Text>
      </Box>
    )
  }

  // TODO: This needs to be refactored and probably deprecated completely.
  // For now, add a small fix to give the anchor element the aria-label
  // to help with testing
  const { 'aria-label': ariaLabel, ...withoutAriaLabel } = rest

  return (
    <Link href={href} aria-label={ariaLabel} onClick={onClick}>
      <Box display="block" {...wrapperProps}>
        <Text cursor="pointer" {...withoutAriaLabel}>
          {children}
        </Text>
      </Box>
    </Link>
  )
}

export const ImageLink: FC<ImageLinkProps & ImageStyleProps> = ({
  href,
  wrapperProps,
  ...rest
}: ImageLinkProps) => (
  <Link href={href}>
    <Box display="block" {...wrapperProps}>
      <Image {...rest} />
    </Box>
  </Link>
)

//from flowcode
export const BoxLink: FC<BoxLinkProps> = ({
  children,
  href,
  display = 'block',
  linkProps,
  ...rest
}: BoxLinkProps) => {
  return (
    <Link href={href} {...linkProps}>
      <Box display={display} {...rest}>
        {children}
      </Box>
    </Link>
  )
}

export const typeVenmoUsername = (
  e: ChangeEvent<HTMLInputElement>,
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
): void => {
  const usernameShouldUpdate = getVenmoUsernameShouldUpdate(e.target.value)
  if (usernameShouldUpdate) handleChange(e)
}

export const REGEX_ALPHANUMERIC = /^[a-zA-Z0-9-_]+$/

export const getVenmoUsernameShouldUpdate = (username: string): boolean => {
  const isAllowed = REGEX_ALPHANUMERIC.test(username?.slice(1))
  const isEmpty = username === '@'
  return isAllowed || isEmpty
}

export const parseInternalLinks = (url: string): string => {
  const flowcodeAppDomainRegEx = /^(http|https):\/\/(app\.)?flowcode.com\/?/i
  const flowpageDomainRegEx = /^(http|https):\/\/(www\.)?flow.page\/?/i

  return url.replace(flowcodeAppDomainRegEx, '/').replace(flowpageDomainRegEx, '/page')
}

export const urlObjectToString = (urlObject: UrlObject): string => {
  const { pathname, query } = urlObject
  if (!query) return pathname ?? ''
  const queryKeys = Object.keys(query as ParsedUrlQueryInput)
  const queryValues = Object.values(query as ParsedUrlQueryInput)
  const queryStrings = queryKeys.map((key, index) => `${key}=${queryValues[index]}`)
  if (!queryKeys.length) return pathname ?? ''
  return `${pathname}?${queryStrings.join('&')}`
}
