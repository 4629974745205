import { SVGProps, memo } from 'react'

function EditSvg({ fill = 'currentcolor', color, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...rest}>
      <g clipPath="url(#prefix__prefix__clip_edit)">
        <path
          fill={color || fill}
          fillRule="evenodd"
          d="M15.797 8.844l.262-.262.798-.798.263-.263 1.765-1.765A3.37 3.37 0 1014.116.988l-1.764 1.764-.263.263-.798.798-.263.263L.44 14.665l-.44.439v4.768h4.769l.439-.439L15.797 8.844zm-1.324-6.092l-1.06 1.061 1.06 1.06.526.527 1.06 1.06L17.12 5.4l.704-.705a1.872 1.872 0 10-2.647-2.647l-.704.704zm-3.445 3.445l1.06-1.06 1.061 1.06.526.526 1.06 1.06-1.06 1.061-9.528 9.528H2.56l.353-.352a.749.749 0 10-1.06-1.06l-.353.352v-1.587l9.528-9.528z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="prefix__prefix__clip_edit">
          <path fill="#fff" d="M0 0h19.873v19.872H0V0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Edit = memo(EditSvg)
