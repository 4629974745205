import { DESCRIPTION, DISPLAY_TYPE, EMBED, IMAGE, LINK, TITLE } from './constants'
import { DefaultFieldType, LinkMetaType } from '@dtx-company/inter-app/src/constants/linkTypes'
import { FormEventHandler } from 'react'
import { LinkType } from '../types'
import { SocialLinkFields } from './SocialLinkFields.types'
import { UseFormMethods } from 'react-hook-form-deprecated'
import { UseFormReturn } from 'react-hook-form'

export enum SocialLinkUrlFields {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  SNAPCHAT = 'snapchat',
  TIKTOK = 'tiktok',
  LINKEDIN = 'linkedin',
  SPOTIFY = 'spotify',
  TWITCH = 'twitch',
  PATREON = 'patreon',
  ONLYFANS = 'onlyfans',
  VENMO = 'venmo',
  PINTEREST = 'pinterest',
  CASH_APP = 'cashapp',
  APPLE_MUSIC = 'applemusic',
  AMAZON_MUSIC = 'amazonmusic',
  YOUTUBE_MUSIC = 'youtubemusic',
  TIDAL = 'tidal',
  SOUNDCLOUD = 'soundcloud',
  DEEZER = 'deezer',
  PARADE_DECK = 'paradedeck'
}
export interface LinkFormFields {
  title: string
  link: string
  description: string
  image?: string | null
  embed?: boolean
  displayType?: string
  linkTheme?: LinkType['linkTheme']
}

export interface EmbedProps {
  value: boolean
  setValue: (_1: string, _2: boolean) => void
}

export interface DestinationLinkFormFields {
  [TITLE]: string
  [DESCRIPTION]: string
  [LINK]: string
  [DISPLAY_TYPE]: string
  [IMAGE]: string | null | undefined
  [EMBED]: boolean
}

export interface DestinationLinkFormState {
  disabled: boolean
  watchAll: DestinationLinkFormFields
  onSubmit: FormEventHandler
  clearOGInfo: () => void
  setValue: UseFormReturn<DestinationLinkFormFields>['setValue']
  setPreviewAndFormValue: UseFormReturn<DestinationLinkFormFields>['setValue']
  errors: UseFormReturn<DestinationLinkFormFields>['formState']['errors']
  trigger: UseFormReturn<DestinationLinkFormFields>['trigger']
  id: string
  imageSizeWarning: boolean
  setImageSizeWarning: (_: boolean) => void
  register: UseFormReturn<DestinationLinkFormFields>['register']
  linkTitle: string
  meta: LinkMetaType
  setMeta: React.Dispatch<React.SetStateAction<LinkMetaType>>
  fetchOgInfoFromLink: (url: string) => Promise<void>
  isFetchingOgInfo: boolean
  cancelFetchOgInfoFromLink: () => void
  linkTheme: LinkType['linkTheme']
  setLinkTheme: (linkThemeInput: Partial<LinkType['linkTheme']>) => void
  embed: null
  validateTitle: (value: string | null) => boolean | string
}

export interface DestinationUsernameFormFields {
  [TITLE]: string
  [DESCRIPTION]: string
  [LINK]: string
  [IMAGE]: string
  [DISPLAY_TYPE]: string
}

export interface DestinationUsernameFormState {
  disabled: boolean
  watchAll: DestinationUsernameFormFields
  onSubmit: ({
    title,
    description,
    link,
    displayType,
    image,
    embed
  }: LinkFormFields) => Promise<void>
  setValue: UseFormReturn<DestinationUsernameFormFields>['setValue']
  errors: UseFormReturn['formState']['errors']
  handleSubmit: UseFormReturn<DestinationUsernameFormFields>['handleSubmit']
  trigger: UseFormReturn<DestinationUsernameFormFields>['trigger']
  id: string
  imageSizeWarning: boolean
  setImageSizeWarning: (_: boolean) => void
}

export interface AppStoreLinkFormState {
  disabled: boolean
  watchAll: {
    title: string
    description: string
    appStoreUrl: string
    playStoreUrl: string
    displayType: string
    image: string | null | undefined
    buttonColor: string
  }
  onSubmit: ({
    title,
    description,
    appStoreUrl,
    playStoreUrl,
    displayType,
    image
  }: AppStoreLinkFormFields) => Promise<void>
  setValue: UseFormMethods['setValue']
  errors: UseFormMethods['errors']
  handleSubmit: UseFormMethods['handleSubmit']
  trigger: UseFormMethods['trigger']
  id: string
  imageSizeWarning: boolean
  setImageSizeWarning: (_: boolean) => void
  linkTitle: string
  meta: LinkMetaType
}

export interface AppStoreLinkFormFields {
  appStoreUrl?: string
  playStoreUrl?: string
  title: string
  description: string | null
  image: string | null
  displayType: string
  buttonColor: string
}

export interface SocialProviderFields {
  instagram?: SocialLinkFields
  facebook?: SocialLinkFields
  twitter?: SocialLinkFields
  youtube?: SocialLinkFields
  snapchat?: SocialLinkFields
  tiktok?: SocialLinkFields
  linkedin?: SocialLinkFields
  spotify?: SocialLinkFields
  twitch?: SocialLinkFields
  patreon?: SocialLinkFields
  onlyfans?: SocialLinkFields
  venmo?: SocialLinkFields
  pinterest?: SocialLinkFields
  cashapp?: SocialLinkFields
  applemusic?: SocialLinkFields
  amazonmusic?: SocialLinkFields
  youtubemusic?: SocialLinkFields
  soundcloud?: SocialLinkFields
  tidal?: SocialLinkFields
  deezer?: SocialLinkFields
  paradedeck?: SocialLinkFields
}
export interface SocialLinkFormFields extends SocialProviderFields {
  displayType: string
  iconSize: string
  iconStyle: string
}

export interface TextBoxSectionFormFields {
  title: string
  size: number
}
export interface SocialAdvLinkType {
  provider: SocialLinkUrlFields
  prefix?: string
  displayText?: string
  adornment?: string
  type?: string
  isUsernameLink?: boolean
  defaultFields?: DefaultFieldType
}
