import { PlanType } from '@dtx-company/true-common/src/types/planType'

export function getPlanTypeFromPlan(plan: string): PlanType | undefined {
  switch (plan) {
    case 'personal_plan:free':
      return PlanType.FREE
    case 'personal_plan:pro':
      return PlanType.PRO
    case 'org_plan:pro_plus':
      return PlanType.PRO_PLUS_ORG
    case 'org_plan:pro_flex':
      return PlanType.PRO_FLEX
    case 'org_plan:pilot':
      return PlanType.PILOT
    case 'org_plan:enterprise':
      return PlanType.ENTERPRISE
    default:
      return undefined
  }
}
