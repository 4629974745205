import {
  LandingPageDestination,
  LandingPageDestinationType
} from '@dtx-company/inter-app/src/types/flowcode'

export const WEBSITE_DESTINATION: LandingPageDestinationType = {
  label: 'Website',
  id: LandingPageDestination.URL,
  src: '',
  alt: 'website',
  placeholder: 'Enter or paste a URL',
  alfredIcon: true
}

export const FILE_DESTINATION: LandingPageDestinationType = {
  label: 'File',
  id: LandingPageDestination.FILE,
  src: '',
  alt: 'file',
  placeholder: 'my_file (20mb limit)',
  alfredIcon: true
}

export const SMS_DESTINATION: LandingPageDestinationType = {
  label: 'SMS',
  id: LandingPageDestination.SMS,
  src: '',
  alt: 'sms',
  placeholder: '555-555-5555',
  alfredIcon: true
}

export const IMAGE_DESTINATION: LandingPageDestinationType = {
  label: 'Image',
  id: LandingPageDestination.IMAGE,
  src: '',
  alt: 'Image',
  placeholder: 'myfile.png',
  alfredIcon: true
}

export const EMAIL_DESTINATION: LandingPageDestinationType = {
  label: 'Email',
  id: LandingPageDestination.EMAIL,
  src: '',
  alt: 'email',
  placeholder: 'email@domain.com',
  alfredIcon: true
}

export const FACEBOOK_DESTINATION: LandingPageDestinationType = {
  label: 'Facebook',
  id: LandingPageDestination.FACEBOOK,
  src: '',
  alt: 'facebook',
  placeholder: 'GetFlowcode, facebook.com/GetFlowcode/posts/123456',
  alfredIcon: true
}

export const YOUTUBE_DESTINATION: LandingPageDestinationType = {
  label: 'Youtube',
  id: LandingPageDestination.YOUTUBE,
  src: '',
  alt: 'youtube',
  placeholder: 'youtube.com/Flowcode',
  alfredIcon: true
}

export const GOOGLE_DOC_DESTINATION: LandingPageDestinationType = {
  label: 'Google doc',
  id: LandingPageDestination.GOOGLE_DOC,
  src: '',
  alt: 'google doc',
  placeholder: 'docs.google.com/document',
  prefix: 'docs.google.com'
}
