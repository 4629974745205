import { Box } from '../Box'
import { SpinnerProps } from '../../../types/atoms'
import { layout, position, space } from 'styled-system'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
 `

const Spin = styled(Box)`
  height: inherit;
  width: inherit;
  box-sizing: border-box;
  margin-right: 10px;
  border: 1px solid currentcolor;
  border-right-color: transparent;
  border-radius: 100%;
  animation: ${rotate} 1s infinite linear;
  ${layout}
  ${position}
  ${space}
`

export const Spinner = (props: SpinnerProps): JSX.Element => {
  return <Spin {...props} />
}
