import { Box } from '../Box'
import {
  ComponentType,
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState
} from 'react'
import { InputBaseProps, InputMode, InputProps } from '../../../types/atoms/input.types'
import { Text } from '../Text'
import { border, color, space, typography } from 'styled-system'
import { setRef } from '@mui/material/utils'
import { theme } from '../../../theme'
import styled from 'styled-components'
import useComponentSize from '../../../hooks/useComponentSize'

const InputBase: ComponentType<InputBaseProps> = styled.input<InputBaseProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  background: ${props => (props.background ? props.background : 'transparent')};
  width: 100%;
  line-height: normal;
  outline: none;
  border: solid 1px ${({ theme }) => theme.colors.secondary.border};
  border-radius: 4px;
  &:hover {
    border: solid 1px #636565;
  }
  &::placeholder {
    line-height: inherit;
  }
  &::-moz-placeholder {
    line-height: revert;
  }
  &:focus {
    color: ${({ theme, focusColor }) => (focusColor ? focusColor : theme.colors.primary.black)};
    border: solid 1px ${({ focusBorderColor }) => focusBorderColor};
  }
  &:disabled {
    border: solid 1px ${({ theme }) => theme.colors.secondary.border};
  }
  font-family: ${({ theme }) => theme.fontFamily};
  ${({ disableResize, resizeDirection }) => {
    if (disableResize) {
      return `resize: none;`
    } else if (resizeDirection) {
      return `resize: ${resizeDirection};`
    }
  }}
  ${border}
  ${typography}
  ${space}
  ${color}
`

export const Input: ForwardRefExoticComponent<InputProps & RefAttributes<HTMLInputElement>> =
  forwardRef<HTMLInputElement, InputProps>(
    (
      {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange = () => {},
        onKeyPress,
        type = 'text',
        inputMode,
        value,
        onFocus,
        autoFocus = false,
        label,
        helperText,
        helperTextProps,
        fontSize = '16px',
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onBlur = () => {},
        placeholder,
        height,
        width = '100%',
        startAdornment,
        endAdornment,
        disabled,
        multiline,
        error,
        defaultValue = '',
        id,
        inputProps,
        labelProps,
        onClear,
        controlled = false,
        enableResize,
        resizeDirection,
        focusBorderColor = theme.colors.primary.black,
        ariaLabelledBy,
        startAdornmentWrapperProps = {},
        autoComplete,
        ...rest
      },
      ref
    ) => {
      const _inputId = useId()
      const inputId = id || _inputId

      const [bg, setBG] = useState<string>('#FFF')
      const [borderColor, setBorderColor] = useState<string>('secondary.border')
      const startRef = useRef<HTMLDivElement>(null)
      const { width: startRefWidth } = useComponentSize(startRef)
      const startWidth = `${Math.round(startRefWidth) + 6}px`
      const [endWidth, setEndWidth] = useState<string>('48px')
      const [entered, setEntered] = useState<boolean>(!!defaultValue)
      const endRef = useRef<HTMLDivElement>(null)
      const inputRef = useRef<HTMLInputElement>(null)
      const handleInputRef = useCallback(
        (node: HTMLInputElement) => {
          setRef(inputRef, node)
          setRef(ref, node)
        },
        [ref]
      )

      useEffect(() => {
        setBG('#FFF')
        setBorderColor('secondary.border')
        if (entered) {
          setBG('#f3f5f6')
        }
        if (error) {
          setBG('#fbedee')
          setBorderColor('status.errorDark')
        }
        if (disabled) {
          setBG('#dadcdd')
        }
      }, [disabled, entered, error])

      // TODO: rewrite with useComponentSize
      // The variable value is watched in the useEffect to recalculate the width accounting for the newly displayed UI elements
      // when the Input's value has changed. Specifically relevant when using a FileInput component.
      useEffect(() => {
        const dims = endRef?.current?.getBoundingClientRect() as DOMRect
        if (dims?.width) {
          setEndWidth(`${Math.round(dims.width) + 6}px`)
        }
      }, [endRef, value])

      const handleClear = (): void => {
        if (!!onClear && !!inputRef.current) {
          onClear()
          inputRef.current.value = ''
          setEntered(false)
        }
      }

      useEffect(() => {
        if (inputRef.current && autoFocus) {
          inputRef.current.focus()
        }
      }, [inputRef, autoFocus])

      const inputHeight = height || '44px'
      return (
        <Box display="block" width={width} maxWidth={rest.maxWidth}>
          {label && (
            <Text component="label" variant="body/small" {...labelProps} htmlFor={inputId}>
              {label}
            </Text>
          )}
          <Box
            bg={bg}
            height={inputHeight}
            position="relative"
            borderRadius={1}
            maxWidth="305px"
            width={width}
            {...rest}
          >
            {startAdornment && (
              <Box
                ref={startRef}
                height={inputHeight}
                alignItems="center"
                left="2px"
                position="absolute"
                {...startAdornmentWrapperProps}
              >
                {startAdornment}
              </Box>
            )}
            <InputBase
              onChange={onChange}
              onKeyPress={onKeyPress}
              autoComplete={autoComplete}
              disabled={disabled}
              onBlur={e => {
                if (e.target.value.length > 0) {
                  setEntered(true)
                } else {
                  setEntered(false)
                }
                onBlur(e)
              }}
              onFocus={onFocus}
              type={type}
              pt={multiline ? '9px' : null}
              as={multiline ? 'textarea' : 'input'}
              pl={startAdornment ? startWidth : '17px'}
              pr={endAdornment ? endWidth : '0px'}
              borderColor={borderColor}
              placeholder={placeholder}
              fontSize={fontSize}
              id={inputId}
              aria-label={label}
              aria-labelledby={ariaLabelledBy}
              ref={handleInputRef}
              {...(controlled ? { value } : { defaultValue: value || defaultValue })}
              {...inputProps}
              disableResize={Boolean(multiline && height && !enableResize)}
              resizeDirection={resizeDirection}
              focusBorderColor={focusBorderColor}
              inputMode={
                inputMode ?? Object.values(InputMode).includes(type as InputMode)
                  ? (type as InputMode)
                  : InputMode.TEXT
              }
            />
            {endAdornment && (
              <Box
                ref={endRef}
                height={inputHeight}
                alignItems="center"
                right="2px"
                position="absolute"
                onClick={onClear ? handleClear : null}
                cursor={onClear ? 'pointer' : 'auto'}
                aria-label="icon-aria-label"
              >
                {endAdornment}
              </Box>
            )}
          </Box>
          {helperText && (
            <Text
              color={error ? 'status.errorDark' : 'primary.black'}
              variant="body/small"
              pt="4px"
              {...(error && { role: 'alert' })}
              {...helperTextProps}
            >
              {helperText}
            </Text>
          )}
        </Box>
      )
    }
  )
Input.displayName = 'Input'
